
  import { defineComponent, reactive, ref, computed } from "vue"
  // import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  export default defineComponent({
    props: {
      data: {
        type: Object,
        default: () => ({})
      },
    }
  })
