import { CollectionResourceDoc, ResourceObject } from '@/models/jsonapi'
import { localIndex, round, defObj, endOfMonth, shortDate } from '@/utils/helper'
import { Segment } from "@/models/segments"

export const segmentSellerProvision = (segment: ResourceObject<string, Segment>) => {
  const prov = segment.attributes?.provSeller
  const discountCg = segment.attributes?.discountCg
  if (typeof(prov) ===  'number' && typeof(discountCg) ===  'number') {
    return round((prov-discountCg) * durationFactor(segment))
  } else {
    console.log('segmentSellerProvision NaN: ', segment);
    throw 'segmentSellerProvision NaN segmentId: '+segment.id
  }
}

export const segmentAgencyProvision = (segment: ResourceObject<string, Segment>) => {
  const pS = segment.attributes?.provSeller
  const pP = segment.attributes?.provPfs
  const dCg = segment.attributes?.discountCg
  if (typeof(pS) === 'number' && typeof(pP) ===  'number' && typeof(dCg) ===  'number') {
    return round((pS+pP-dCg) * durationFactor(segment))
  } else {
    console.log('segmentAgencyProvision NaN: ', segment);
    throw 'segmentAgencyProvision NaN segmentId: '+segment.id
  }
}

export const providerSegmentAgencyProvision = (segment: ResourceObject<string, Segment>) => {
  const pAgT = segment.attributes?.provAgencyTotal
  const dCg = segment.attributes?.discountCg
  if (typeof(pAgT) ===  'number' && typeof(dCg) ===  'number') {
    return round((pAgT-dCg) * providerDurationFactor(segment))
  } else {
    console.log('providerSegmentAgencyProvision NaN: ', segment);
    throw 'providerSegmentAgencyProvision NaN segmentId: '+segment.id
  }
}

const durationFactor = (segment: ResourceObject<string, Segment>) => {
  if (segment.attributes?.start) {
    const monthDays = endOfMonth(segment.attributes.start).getDate()
    const duration = segmentDuration(segment)
    if (typeof(duration)==='number') {return duration / monthDays} else {
      console.log('durationFactor duration missing for: ', segment);
      throw 'durationFactor duration missing for segmentId: '+segment.id
    }
  } else {
    console.log('durationFactor start missing for: ', segment);
    throw 'durationFactor start missing for segmentId: '+segment.id
  }
}

const providerDurationFactor = (segment: ResourceObject<string, Segment>) => {
  if (segment.attributes?.agStart) {
    const monthDays = endOfMonth(segment.attributes.agStart).getDate()
    const duration = providerSegmentDuration(segment)
    if (typeof(duration)==='number') {return duration / monthDays} else {
      console.log('durationFactor duration missing for: ', segment);
      throw 'durationFactor duration missing for segmentId: '+segment.id
    }
  } else {
    console.log('durationFactor agStart missing for: ', segment);
    throw 'durationFactor agStart missing for segmentId: '+segment.id
  }
}

export const segmentDuration = (segment: ResourceObject<string, Segment>) => {
  if (!segment.attributes?.start) { return null }
  if (typeof(segment.attributes?.manualDuration)==='number') { return segment.attributes.manualDuration }
  const att = segment.attributes
  if (att && att.start) {
    const start = new Date(segment.attributes?.start)
    const endString = segment.attributes?.end
    const end = endString ? new Date(endString) : endOfMonth(segment.attributes?.start)
    const newDiff = 1+Math.round((end.getTime()-start.getTime())/(24*60*60*1000))
    const oldDiff = end.getDate() - start.getDate() + 1
    // if (newDiff != oldDiff) {console.log('diff err', newDiff, oldDiff, 'start', start, 'end', end)}
    return newDiff
  } else {
    console.log('Duration NaN: ', segment);
    throw 'Duration NaN segmentId: '+segment.id
  }
}

export const providerSegmentDuration = (segment: ResourceObject<string, Segment>) => {
  if (!segment.attributes?.agStart) { return null }
  if (segment.attributes?.manualDuration) { return segment.attributes.manualDuration }
  const att = segment.attributes
  if (att && att.agStart) {
    const start = new Date(segment.attributes?.agStart)
    const endString = segment.attributes?.agEnd
    const end = endString ? new Date(endString) : endOfMonth(att.agStart)
    const newDiff = 1+Math.round((end.getTime()-start.getTime())/(24*60*60*1000))
    const oldDiff = end.getDate() - start.getDate() + 1
    if (newDiff != oldDiff) {console.log('provider diff err', newDiff, oldDiff)}
    return newDiff
  } else {
    console.log('Provider Duration NaN: ', segment);
    throw 'Provider Duration NaN segmentId: '+segment.id
  }
}

interface Discounts {
  [key:string]: number
}

interface OverviewData {
  id: string,
  billNr: string,
  discounts: Discounts,
  provision: number,
  leads: number,
  reclaimed: number,
  license: number,
  totalDiscount: number,
  leadCount: number,
  reclaimCount: number,
  segmentCount: number,
  taxRate: number,
  manEntrySum: number,
  netto?: number,
  brutto?: number,
  paid: boolean,
}

export const computeSellerBillOverviewData = (data: any):OverviewData => {
  const b2s = localIndex(data.segmentData, (e) => e.attributes.sellerBillId)
  const b2l = localIndex(data.leadData, (e) => e.sellerBillId)
  const b2rl = localIndex(data.leadData, (e) => e.reclaimBillId || e.sellerBillId)
  return data.sellerBillData.map((bill) => {
    const atts = bill.attributes
    const discountList = makeDiscountList(b2s(bill.id)||[])
    const reclaimCount = b2rl(bill.id)?.filter((l) => (l.reclaimed && l.reclaimConfirmed)).length||0
    const result:OverviewData = {
      id: bill.id,
      billNr: atts.billNr,
      // month: bill.attributes.month,
      provision: billSellerProvision(b2s(bill.id)||[]),
      leads: -1 * leadCost(b2l(bill.id)),
      leadCount: b2l(bill.id)?.length||0,
      reclaimCount,
      segmentCount: b2s(bill.id)?.length||0,
      reclaimed: reclaimCost(b2rl(bill.id)),
      license: bill.meta?.license,
      discounts: discountList,
      totalDiscount: Object.values(discountList).reduce((r:number,e:number) => r+=e, 0),
      paid: bill.attributes.paid,
      taxRate: bill.attributes.taxRate,
      manEntrySum: billManEntriesSum(bill)
    }
    result.netto = sellerBillTotal(result)
    result.brutto = sellerBillTotal(result, true)
    return result
  })
}

export const totals = () => ({ total: 0, billed: 0, unBilled: 0, paid: 0, unPaid: 0 })

const sellerBillTotal = (data: OverviewData, tax: boolean = false) => {
  const {provision, leads, reclaimed, totalDiscount, license, manEntrySum} = data
  const taxFactor = tax ? data.taxRate+1 : 1
  return round((provision + leads + reclaimed + totalDiscount + license + manEntrySum) * taxFactor)
}

export const makeBillId2BillInfo = (segments: ResourceObject<string, Segment>[]) => {
  const res = {freeSegments: {segments:[] as any[]}}
  segments.forEach((seg) => {
    const billId = seg.attributes?.agencyBillId
    if (typeof(billId) === 'string' && billId.length > 0) {
      if (res.hasOwnProperty(billId)) {
        res[billId].segments.push(seg)
      } else {
        res[billId] = {segments: [seg]}
      }
    } else {
      res.freeSegments.segments.push(seg)
    }
  })
  return res
}

// export const calculateSegmentTotals = (segments: ResourceObject<string, Segment>[]) => {
//   const reducer = (totals, seg) => {
//     const info = agencyBillSegmentInfo(seg)
//     const prov = info.provision
//     totals.total += prov
//     info.billed ? totals.billed += prov : totals.unBilled += prov
//     info.paid ? totals.paid += prov : totals.unPaid += prov
//     return totals
//   }
//   return segments.reduce(reducer, totals())
// }


export const sellerBillKeyData = (overviewData: OverviewData, bill: any) => {
  const att = bill.attributes
  const info = {
    'Rechnungsnummer': att.billNr,
    'Bezahlt': att.paid ? 'Ja' : 'Nein',
    'Erworbene Leads': overviewData.leadCount,
    'Reklamierte Leads': overviewData.reclaimCount,
    'Pflegeabschnitte': overviewData.segmentCount,
  }
  const {provision, leads, reclaimed, license, totalDiscount, manEntrySum} = overviewData
  const positions = {
    'Provision': provision,
    'Leadkosten': leads,
    'Reklamiert': reclaimed,
    'Lizens': license,
    'Manuelle Einträge': manEntrySum,
    ...overviewData.discounts,
  }
  const net:number = Object.values(positions).reduce((r:number,e:number)=>(r+=e),0)
  const percentTax = round((att.taxRate)*100)
  const totals = {
    'Netto': round(net,2),
    [`zzgl. ${percentTax}% USt.`]: round(att.taxRate * net),
    'Brutto': round(att.taxRate * net + net),
  }
  return { info, positions, totals }
}

export const agencyBillKeyData = (bill: any, segs: any, discountNameKey:string) => {
  const att = bill.attributes
  const billNr = (att.presented && att.reviewed) ? att.billNr : 'keine'
  const info = {
    'Rechnungsnummer': billNr,
    'Prüfung': att.presented ? 'Ja' : 'Nein',
    'Geprüft': att.reviewed ? 'Ja' : 'Nein',
    'Gezahlt': att.paid ? 'Ja' : 'Nein',
    'Pflegeabschnitte': segs.length,
  }
  const manEntrySum = billManEntriesSum(bill)
  const positions = {
    'Provision': billProvision(segs),
    'Manuelle Einträge': manEntrySum,
    ...makeNamedDiscountList(segs, discountNameKey)
  }
  const net:number = Object.values(positions).reduce((r:number,e:number)=>(r+=e),0)
  const totals = { 'Total': round(net,2) }
  return { info, positions, totals }
}

export const agencyBillKeyDataNew = (bill: any, segs: any, discountNameKey:string) => {
  const att = bill.attributes
  const info = {
    'Rechnungsnummer': (att.presented && att.reviewed) ? att.billNr : 'keine',
    'Rechnungsdatum': shortDate(att.billDate),
    'Prüfung': att.presented ? 'Ja' : 'Nein',
    'Geprüft': att.reviewed ? 'Ja' : 'Nein',
    'Bezahlt': att.paid ? 'Ja' : 'Nein',
    'Pflegeabschnitte': segs.length,
  }
  const manEntrySum = billManEntriesSum(bill)
  const provision = segs.map((s:any) => Math.round(s.meta.provisionProportional*100))
    .reduce((r:number,p:number)=>(r+=p),0)/100
  const positions = {
    'Provision': provision,
    'Manuelle Einträge': manEntrySum,
    ...makeNamedDiscountList(segs, discountNameKey)
  }
  const net:number = Object.values(positions).reduce((r:number,e:number)=>(r+=e),0)
  const totals = { 'Total': round(net,2) }
  return { info, positions, totals }
}

const billSellerProvision = (segments: ResourceObject<string, Segment>[]) => {
  return round(segments.reduce((r,s) => (r+=segmentSellerProvision(s)), 0))
}

const billProvision = (segments: ResourceObject<string, Segment>[]) => {
  return round(segments.reduce((r,s) => (r+=s.meta?.provisionProportional), 0))
}

const leadCost = (leads: any[]) => {
  if (!leads) {return 0}
  return leads.reduce((res, lead) => {
    return res += lead.price
  }, 0)
}

const reclaimCost = (leads: any[]) => {
  if (!leads) {return 0}
  return leads.reduce((res, lead) => {
    if (lead.reclaimed&&lead.reclaimConfirmed) {
      return res += lead.price
    }
    return res
  }, 0)
}

const makeDiscountList = (segments: ResourceObject<string, Segment>[]):Discounts => {
  return segments.reduce((list, s) => {
    if (s.attributes) {
      const d = s.attributes.discountSeller
      if (d > 0) { list[`Rabatt ${s.meta?.leadFullName}`] = d * -1 }
    }
    return list
  }, {})
}

const makeNamedDiscountList = (segments: ResourceObject<string, Segment>[],nameKey:string):Discounts => {
  return segments.reduce((list, s) => {
    if (s.attributes) {
      const d = s.attributes.discountSeller
      if (d > 0) { list[`Rabatt ${s.meta?.[nameKey]}`] = d * -1 }
    }
    return list
  }, {})
}

export const extendAgencyBillSegment = (seg: ResourceObject<string, Segment>) => {
  if (seg.attributes) {
    seg.meta = {...(seg.meta||{}), ...{
      provision: seg.attributes.provSeller + seg.attributes.provPfs - seg.attributes.discountCg,
      provisionProportional: segmentAgencyProvision(seg),
      duration: segmentDuration(seg),
      closed: !!seg.attributes.end,
      // monthEnd: endOfMonth(seg.attributes.start).toISOString()
    }}
  }
  return seg
}

export const extendProviderAgencyBillSegment = (seg: ResourceObject<string, Segment>) => {
  if (seg.attributes) {
    seg.meta = {
      provision: seg.attributes.provAgencyTotal - seg.attributes.discountCg,
      provisionProportional: providerSegmentAgencyProvision(seg),
      duration: providerSegmentDuration(seg),
      closed: !!seg.attributes.agEnd,
      // monthEnd: endOfMonth(seg.attributes.agStart||seg.attributes.start).toISOString()
    }
  }
  return seg
}

export const billVariantIndex = (bill: any) => {
  const {presented, reviewed, paid} = bill.attributes
  if (paid) return 4
  let idx
  if (presented && reviewed) {idx = 3}
  else if (!presented && reviewed) {idx = 2}
  else if (presented && !reviewed) {idx = 1}
  else if (!presented && !reviewed) {idx = 0}
  return idx
}

export const ranks = ['secondary', 'warning', 'info', 'primary', 'success']

// Below is a late and complicated attempt

// const agencyBillSegmentInfo = (segment: ResourceObject<string, Segment>) => {
//   const provision = segmentAgencyProvision(segment)
//   if (typeof(provision) != 'number') {
//     console.log('no provision Segment: ', segment)
//     throw 'agencyBillSegmentInfo: no Provision for Segment: '+segment.id
//   }
//   if (typeof(segment.attributes?.agencyBillPaid) != 'boolean') {
//     console.log('wrong agencyBillPaid Segment: ', segment)
//     throw 'agencyBillSegmentInfo: agencyBillPaid is not boolean for segment with Id: '+segment.id
//   }
//   const atts = segment.attributes
//   if (atts) {
//     return {
//       provision,
//       billId: atts.agencyBillId,
//       billed: typeof(atts.agencyBillId) === 'string',
//       paid: atts.agencyBillPaid,
//     }
//   } else {
//     console.log('no attributes Segment: ', segment)
//     throw 'agencyBillSegmentInfo: no attributes found for segment with Id: '+segment.id
//   }
// }

// export const computeAgencyBillData_old = (data: any) => {
//   const hd = totals()
//   const agid__billData = defObj(()=>defObj(()=>({})))
//   const cid__agid = {}
//   data.contractData.forEach((c) => {cid__agid[c.id] = c.attributes.agencyId})
//   (data.segmentData as ResourceObject<string, Segment>[]).forEach((s) => {
//     const info = agencyBillSegmentInfo(s)
//     const contractId = s.attributes?.contractId
//     const agencyId = cid__agid[contractId]
//     hd.total += info.provision
//     info.billed ? hd.billed += info.provision : hd.unBilled += info.provision
//     info.paid ? hd.paid += info.provision : hd.unPaid += info.provision
//   })
// }

const agencyDataEntry = (agency: any) => ({
  agTotal: totals(),
  bills: {}, freeSegments: []
})

export const segmentsProvisionSum = (segments: [any]) => {
  const unroundedSum = segments.reduce((res, s) => {
    return (res += s.meta.provisionProportional)
  }, 0)
  return Math.round(unroundedSum*100)/100
}

export const summizeList = (list: any, getter: any) => {
  const values = list.map(getter)
  const res = summizeValueList(values)
  // console.log('summizeList result: ', res)
  return res
}

const summizeValueList = (list: [any]) => {
  return list.reduce((sum: number, val: number) => {
    return sum += Math.round(val*100)
  },0)/100
}

export const billManEntriesSum = (bill: any) => {
  if (bill.attributes?.entries && bill.attributes.entries.length > 0) {
    return (bill.attributes.entries.reduce((r:number,a:{[key:string]: any})=>{
      return r+=round(100 * parseFloat(a.attributes.grossValue))
    }
    , 0))/100
  } else {
    return 0
  }
}

export const pdfStringToCents:Function = (str: string):number => Math.round(parseFloat(str.replace(',','.'))*100)
export const floatTopdfString:Function = (f: number):string => {
  let [i,d] = f.toString().split('.')
  return `${i},${((d||'')+'00').slice(0,2)}`
}

export const lastMonth:Function = (date) => {
  const [y,m,d] = [date.getFullYear(),date.getMonth(),date.getDate()]
  return new Date(y, m-1, d)
}